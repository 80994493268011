// Chakra imports
import {Avatar, Box, Flex, Text, useColorModeValue} from '@chakra-ui/react';

// Custom components
import {ChainpoolLogo} from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import avatar11 from "../../../assets/img/avatars/avatar11.png";

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');
	const textColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			<ChainpoolLogo h='26px' w='175px' my='32px' color={logoColor} />
			<Flex  mb='10px' justifyContent='center' alignItems='center'>
				<Avatar h='48px' w='48px' src={avatar11} me='20px' />
				<Box>
					<Text color={textColor} fontSize='md' fontWeight='700'>
						Bukoi
					</Text>
					<Text color='secondaryGray.600' fontSize='sm' fontWeight='400'>
						Backend Software Engineer
					</Text>
				</Box>
			</Flex>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
